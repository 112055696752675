

// Mood Board Color
export const BG_PRIMARY = '#9C93B6';
export const BG_SECONDARY = '#D2CDDC';
export const BG_ALTERNATIVE = '#8E80B8';
export const BG_OPACITY = '#BCB8C8';
export const BG_LAYER = 'rgb(255 248 241 / 61%)'
export const BG_LAYER_BORDER = '#A48761'

// GRADIENT COLORS
export const GRADIENT_COVER = `linear-gradient(to bottom, ${BG_PRIMARY}, ${BG_SECONDARY})`
export const BUTTON_GRADIENT = `linear-gradient(to left, ${BG_ALTERNATIVE}, ${BG_OPACITY} 140%)`;

// Text Color
export const TEXT_PRIMARY = '#735C3D'; //brown dark
export const TEXT_SECONDARY = '#735C3D'; // Title color section
export const TEXT_ALTERNATIVE = '#8E80B8'; // Alternative Color purple

// Music & Navigation Color
export const NAVIGATION_COLOR = BG_ALTERNATIVE; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#EDE0D1';
export const FOOTER_COLOR = '#7e6c696e';

// Default Button Color
export const BUTTON_PROPS = {
  _active: { opacity: '.8' },
  _hover: { opacity: '.8' },
  _focus: { opacity: '.8' },
};

import { CONTENT_STYLES } from '@/constants/typography'

export const BUTTON_PADDING_PROPS = {
  ...BUTTON_PROPS,
  ...CONTENT_STYLES,
  variant: 'solid',
  padding: '18px 24px',
  borderRadius: '40px',
  background: BUTTON_GRADIENT,
  color: 'white',
  size: 'sm',
  boxShadow: 'sm',
  fontStyle: 'italic'
};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'normal',
};
